@import "./variables.scss";
.fullscreen-wrapper {
  position: fixed;
  right: 20px;
  top: 20px;
  z-index: 9999;
  cursor: pointer;
  opacity: 0.65;
  transition: 0.4s all;

  &:hover {
    opacity: 1;
  }

  svg {
    width: 30px;
    height: 30px;
  }
}
.neon {
  color: #ffd5ff;
  font-weight: 600;
  letter-spacing: 8px;
  text-shadow: 1px 0px 4px #ffd5ff, 2px 0px 4px #ffd5ff, 3px 0px 4px #ffd5ff,
    2px 0px 3px #d42cca, 2px 3px 15px #d42cca, 2px 0px 15px, 5px 0px 125px,
    20px 0vw 200px #d42cca, 40px 0vw 200px #d42cca;
}

input {
  &:focus {
    outline: none;
    box-shadow: none;
  }
}

.inner-container {
  max-width: 1168px;
  padding: 30px;
  margin: 0 auto;

  @media (max-width: 575px) {
    padding: 15px;
  }
}

.rangeSlider {
  -webkit-appearance: none;
  width: 100%;
  height: 8px;
  border-radius: 100px;
  background: rgba(255, 255, 255, 0.2);
  outline: none;
  opacity: 1;
  -webkit-transition: 0.2s;
  transition: opacity 0.2s;

  &:hover {
    // opacity: 1;
  }

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 10px;
    height: 60px;
    border-radius: 50px;
    background: $white;
    box-shadow: $white 0px 0px 0.2rem, $white 0px 0px 2rem,
      $white 0px 0px 0.8rem, $white 0px 0px 2.8rem, $white 0px 0px 0.4rem inset;
    cursor: pointer;
  }

  &::-moz-range-thumb {
    width: 10px;
    height: 60px;
    border-radius: 50px;
    background: $white;
    box-shadow: $white 0px 0px 0.2rem, $white 0px 0px 2rem,
      $white 0px 0px 0.8rem, $white 0px 0px 2.8rem, $white 0px 0px 0.4rem inset;
    cursor: pointer;
  }
}

.row {
  display: flex;
  flex-wrap: wrap;
}

.switch-field {
  border: 2px solid #cccc;
  width: fit-content;
  display: flex;
  border-radius: 6px;

  input {
    display: none;

    &:checked ~ label {
      background: #cccc;
      color: #000;
    }
  }

  label {
    color: #cccc;
    padding: 12px 25px;
    display: flex;
    cursor: pointer;
  }
}

.toggle-button-cover {
  display: table-cell;
  position: relative;
  width: 75px;
  height: 40px;
  box-sizing: border-box;

  .button-cover,
  .knobs,
  .layer {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  .button {
    position: relative;
    top: 50%;
    width: 74px;
    height: 36px;
    margin: -20px auto 0 auto;
    overflow: hidden;
  }

  .button.r,
  .button.r .layer {
    border-radius: 100px;
  }

  .checkbox {
    position: relative;
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;
    opacity: 0;
    cursor: pointer;
    z-index: 3;

    &:checked + .knobs::before {
      content: "Dark";
      left: 4px;
      // background-color: #03a9f4;
      background-color: #fff;
    }

    &:checked ~ .layer {
      // background-color: #fcebeb;
    }
  }

  .knobs {
    z-index: 2;

    &::before {
      content: "Light";
      position: absolute;
      top: 3px;
      left: 37px;
      width: 26px;
      height: 12px;
      color: #000;
      font-size: 10px;
      font-weight: bold;
      text-align: center;
      line-height: 1;
      padding: 9px 4px;
      // background-color: #f44336;
      background-color: #fff;
      border-radius: 47%;
      transition: 0.3s cubic-bezier(0.18, 0.89, 0.35, 1.15) all;
    }
  }

  .layer {
    width: 100%;
    // background-color: #ebf7fc;
    border: 2px solid #ebf7fc;
    transition: 0.3s ease all;
    z-index: 1;
  }

  /* Button 1 */
  .knobs,
  .knobs:before,
  .layer {
    transition: 0.3s ease all;
  }
}

/*---## Preloader ##--*/
.preloader-wrapper {
  background-color: $bg-color;
  height: 100%;
  width: 100%;
  position: fixed;
  z-index: 999999;
  left: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.preload {
  width: 200px;
}

.preload-status {
  overflow: hidden;
  height: 50px;
  border-left: 4px solid #fff;
  border-right: 4px solid #fff;
}

.preload-status-bar {
  position: relative;
  margin-top: 10px;
  width: 100%;
  height: 30px;
  background: #d42cca;
  border: 1px solid #d42cca;
  box-shadow: 0px 0px 4px 1px #d42cca;
  left: -200px;
  animation: move 1.5s infinite cubic-bezier(0.75, 0, 0.25, 1);
}

.preload-status-info {
  opacity: 1;
  margin-top: -22px;
  width: 100%;
  height: 30px;
  font-family: sans-serif;
  font-weight: 700;
  letter-spacing: 5px;
  text-align: center;
  color: #d42cca;
  animation: fade 1.5s infinite cubic-bezier(0.75, 0, 0.25, 1);
}

@keyframes move {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(400px);
  }
}

@keyframes fade {
  0%,
  100% {
    opacity: 1;
  }
  10% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  90% {
    opacity: 1;
  }
}

/*---## Scroll Button ##---*/
.scroll-btn {
  padding-top: 60px;
  cursor: pointer;

  &.sub {
    padding-top: 45px;
    font-size: 14px;
    bottom: 0px;

    @media(max-width: 675px) {
      bottom: 32px;
    }

    span {
      width: 25px;
      height: 37px;
    }
  }
}
.scroll-btn span {
  position: absolute;
  top: 0;
  left: 0%;
  right: 0%;
  width: 30px;
  height: 50px;
  margin: 0 auto;
  border: 2px solid #fff;
  border-radius: 50px;
  box-sizing: border-box;
}
.scroll-btn span::before {
  position: absolute;
  top: 10px;
  left: 50%;
  content: "";
  width: 6px;
  height: 6px;
  margin-left: -3px;
  background-color: #fff;
  border-radius: 100%;
  -webkit-animation: sdb10 2s infinite;
  animation: sdb10 2s infinite;
  box-sizing: border-box;
}

.scroll-btn {
  position: absolute;
  bottom: 10px;
  width: fit-content;
  left: 0;
  right: 0;
  margin: 0 auto;
  z-index: 2;
  display: inline-block;
  -webkit-transform: translate(0, -50%);
  transform: translate(0, -50%);
  color: #fff;
  font: normal 400 20px/1 "Josefin Sans", sans-serif;
  letter-spacing: 0.1em;
  text-decoration: none;
  transition: opacity 0.3s;
}
.scroll-btn:hover {
  opacity: 0.5;
}
@-webkit-keyframes sdb10 {
  0% {
    -webkit-transform: translate(0, 0);
    opacity: 0;
  }
  40% {
    opacity: 1;
  }
  80% {
    -webkit-transform: translate(0, 20px);
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}
@keyframes sdb10 {
  0% {
    transform: translate(0, 0);
    opacity: 0;
  }
  40% {
    opacity: 1;
  }
  80% {
    transform: translate(0, 20px);
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

.border-container {
  border: 2px solid #ffffff80;
  border-radius: 12px;
  padding: 10px 40px;

  &.pd-extra {
    padding: 40px 50px;

    @media (max-width: 575px) {
      padding: 20px 20px;
    }
  }
}

/* width */
::-webkit-scrollbar {
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

body {
  -webkit-user-select: none;
  -webkit-touch-callout: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

::-webkit-scrollbar {
  display: none;
}
