$white: #ffffff;
$black: #000000;
$bg-color: #020228;

/*--### Fonts ###--*/
@font-face {
  font-family: "Audrey Sans";
  src: url(../assets/fonts/AudreySansRegular.otf);
}

@font-face {
  font-family: "BEON";
  src: url(../assets/fonts/Beon.otf);
}

@font-face {
  font-family: "Bristol Signature";
  src: url(../assets/fonts/Bristol_Signature.otf);
}

@font-face {
  font-family: "Cosmopolitan Sans";
  src: url(../assets/fonts/CosmopolitanSansLight.otf);
}

@font-face {
  font-family: "Cosmopolitan Script";
  src: url(../assets/fonts/CosmopolitanScriptLight.otf);
}

@font-face {
  font-family: "Crystal Sky";
  src: url(../assets/fonts/CrystalSky.otf);
}

@font-face {
  font-family: "Dream Catchers Sans";
  src: url(../assets/fonts/DreamCatchersSan.otf);
}

@font-face {
  font-family: "Marquee Moon";
  src: url(../assets/fonts/MarqueeMoon.otf);
}

@font-face {
  font-family: "Qulliness";
  src: url(../assets/fonts/Qulliness.otf);
}

@font-face {
  font-family: "Rodagear";
  src: url(../assets/fonts/Rodagear.otf);
}

@font-face {
  font-family: "Saturday Champagne";
  src: url(../assets/fonts/SaturdayChampagne-Regular.otf);
}

@font-face {
  font-family: "Scarlette";
  src: url(../assets/fonts/Scarlette.ttf);
}

@font-face {
  font-family: "Star Rabit Script";
  src: url(../assets/fonts/StarsRabitScript.otf);
}

@font-face {
  font-family: "Vontage";
  src: url(../assets/fonts/Vontage.otf);
}

@font-face {
  font-family: "WildySign";
  src: url(../assets/fonts/WildySign.otf);
}